//  _____  ______ _____  _____  ______ _____       _______ ______ _____
// |  __ \|  ____|  __ \|  __ \|  ____/ ____|   /\|__   __|  ____|  __ \
// | |  | | |__  | |__) | |__) | |__ | |       /  \  | |  | |__  | |  | |
// | |  | |  __| |  ___/|  _  /|  __|| |      / /\ \ | |  |  __| | |  | |
// | |__| | |____| |    | | \ \| |___| |____ / ____ \| |  | |____| |__| |
// |_____/|______|_|    |_|  \_\______\_____/_/    \_\_|  |______|_____/

// The Open Source Licenses page has been fully migrated to Serve-Next!

// Traffic is no longer being sent to the Serve-Ember version of the page.

// If you need to make changes to the Open Source Licenses page, please do so in Serve-Next instead.

// Open Source Licenses in Serve-Next: https://github.com/ololabs/serve-next/tree/main/src/pages/open-source-licenses

import Route from '@ember/routing/route';

import ENV from 'mobile-web/config/environment';
import { License } from 'mobile-web/lib/license';

export default class OpenSourceLicensesRoute extends Route {
  async model() {
    const licensesResponse = await fetch(
      `${ENV.scriptBaseUrl}assets/open-source-licenses.json?t=${Date.now()}`
    );
    return JSON.parse(await licensesResponse.text()) as License[];
  }
}
