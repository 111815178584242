import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { dropTask, timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { Variant } from 'mobile-web/components/button';
import { GuestUser } from 'mobile-web/lib/customer';
import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import BasketProductModel from 'mobile-web/models/basket-product';
import GroupOrderModel from 'mobile-web/models/group-order';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService, { ON_PREMISE_JUNK_LAST_NAME } from 'mobile-web/services/on-premise';
import SessionService, { User } from 'mobile-web/services/session';
import VendorService from 'mobile-web/services/vendor';

export const GROUP_ORDER_BASKET_REFRESH_INTERVAL = 10000;

export enum GroupOrderParticipantStatus {
  Unknown = 'Unknown',
  Joined = 'Joined',
  InProgress = 'InProgress',
  Submitted = 'Submitted',
  Removed = 'Removed',
}

export function groupOrderDisplayName(guestUser?: GuestUser, user?: User) {
  let names = [];

  if (guestUser) {
    names = [
      guestUser?.firstName,
      guestUser?.lastName === ON_PREMISE_JUNK_LAST_NAME ? undefined : guestUser?.lastName,
    ];
  } else {
    names = [
      user?.firstName,
      user?.lastName === ON_PREMISE_JUNK_LAST_NAME ? undefined : user?.lastName,
    ];
  }

  return guestUser?.fullName ?? names.join(' ').trim();
}
export default class GroupOrderService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service vendor!: VendorService;
  @service router!: RouterService;
  @service error!: ErrorService;

  // Untracked properties
  timeout = timeout; // aids testing of ember timeouts
  private lastOrderUpdateTimestamp?: string;

  // Tracked properties
  @tracked private _groupOrderId?: string;
  @tracked openInviteModal = false;

  // Getters and setters
  get isEnabled(): boolean {
    return (
      (this.onPremise.isEnabled || this.session.signOnAllowed) &&
      this.channel.current?.settings.groupOrdering === true
    );
  }

  get groupOrder(): GroupOrderModel | undefined {
    if (this.groupOrderId) {
      return this.store.peekRecord('group-order', this.groupOrderId) ?? undefined;
    }
    return undefined;
  }

  get hasGroupOrder(): boolean {
    return !!this.groupOrder;
  }

  get isHostMode(): boolean {
    return this.hasGroupOrder && !!this.groupOrder?.currentUserIsHost;
  }

  get isParticipantMode(): boolean {
    return this.hasGroupOrder && !this.isHostMode;
  }

  get groupOrderId(): string | undefined {
    return this._groupOrderId ?? this.basket.basket?.groupOrderId;
  }

  set groupOrderToJoin(value: string | undefined) {
    this._groupOrderId = value;
  }

  get currentUserName(): string {
    // per the function below, localGuestUser will always be preferred if present
    return groupOrderDisplayName(this.session.localGuestUser, this.session.user);
  }

  get currentUserProducts(): BasketProductModel[] {
    const allProducts = this.basket.basket?.basketProducts?.toArray() ?? [];
    return allProducts.filter(bp => bp.recipientName === this.currentUserName);
  }

  get hostDisplayName(): string {
    const hostUser = this.groupOrder?.host as User;
    return groupOrderDisplayName(hostUser);
  }

  get hostDisplayNamePossessive(): string {
    return !this.hostDisplayName ? '' : `${this.hostDisplayName}'s`;
  }

  get canEditGroupOrder(): boolean {
    return this.groupOrder?.status.toLowerCase() === 'created';
  }

  get isCancelled(): boolean {
    return this.groupOrder?.status.toLowerCase() === 'cancelled';
  }

  get isLocked(): boolean {
    return this.groupOrder?.status?.toLowerCase() === 'locked';
  }

  get hasParticipantsInProgress(): boolean {
    return (
      (this.groupOrder!.participants?.filter(
        p => p.status === GroupOrderParticipantStatus.InProgress
      ).length ?? 0) > 0
    );
  }

  get groupOrderInviteUrl(): string {
    const fullSiteUrl = this.channel.settings?.fullSiteUrl ?? '';
    const groupOrderId = this.groupOrderId ?? '';
    return `https://${fullSiteUrl}/group-order/join?groupOrderId=${groupOrderId}`;
  }

  // Lifecycle methods
  constructor() {
    super(...arguments);

    // use Visibility API to suspend polling when document is not visible to user
    // works on Desktop only: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
    document.addEventListener('visibilitychange', this.visibilityChangeHandler);
  }

  willDestroy(): void {
    document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
  }

  // Other methods
  @action
  visibilityChangeHandler() {
    if (document.visibilityState === 'visible') {
      this.refreshBasketTask.perform();
    } else {
      this.refreshBasketTask.cancelAll();
    }
  }

  @action
  showInviteModal() {
    this.bus.trigger('invite', {
      title: this.intl.t('mwc.groupOrder.inviteHeader'),
      description: this.intl.t('mwc.groupOrder.inviteDesc'),
      shareButtonLabel: this.intl.t('mwc.groupOrder.inviteShareButton'),
      url: this.groupOrderInviteUrl,
      testSelector: 'group-order-invite-modal',
      shareButtonAnalytics: AnalyticsEvents.GroupOrderInviteOthersShareLink,
      copyButtonAnalytics: AnalyticsEvents.GroupOrderInviteOthersCopyLink,
      shareDescription: `${this.intl.t('mwc.groupOrder.shareDescription')} ${this.channel.name}!`,
    });
  }

  participantStatus(name: string): GroupOrderParticipantStatus {
    return (
      this.groupOrder?.participants?.find(x => x.participantName === name)?.status ??
      GroupOrderParticipantStatus.Unknown
    );
  }

  statusCount(status: GroupOrderParticipantStatus): number {
    return this.groupOrder!.participants?.filter(part => status === part.status).length ?? 0;
  }

  async loadGroupOrder(): Promise<GroupOrderModel | undefined> {
    if (this.channel.current?.settings.groupOrdering !== true) {
      return undefined;
    }

    const groupOrderId = this.basket.basket?.groupOrderId;
    if (!groupOrderId) {
      return undefined;
    }

    const groupOrder = await this.store.findRecord('group-order', groupOrderId);

    this.refreshBasketTask.perform();
    return groupOrder;
  }

  async startGroupOrder(): Promise<GroupOrderModel | undefined> {
    const groupOrder = this.store.createRecord('group-order');
    groupOrder.basketId = this.basket.basket!.id;
    this.refreshBasketTask.perform();
    const createdGroupOrder = await groupOrder.save();
    await this.basket.refreshBasket();
    return createdGroupOrder;
  }

  async endGroupOrder(): Promise<void> {
    if (this.groupOrder?.currentUserIsHost) {
      //host cancels
      await this.groupOrder?.cancelGroupOrder();
    } else {
      //participant leaves
      this.session.localGuestUser = undefined;
      await this.groupOrder?.updateParticipantStatus({
        participantName: this.currentUserName,
        status: GroupOrderParticipantStatus.Removed,
      });
    }

    this.analytics.trackEvent(
      // @ts-ignore -- temp debug event, remove after OLO-105629
      'End Group Order'
    );
    await this.cleanupGroupOrder();
  }

  async cleanupGroupOrder(): Promise<void> {
    this._groupOrderId = undefined;
    this.refreshBasketTask.cancelAll();

    this.analytics.trackEvent(
      // @ts-ignore -- temp debug event, remove after OLO-105629
      'Cleanup Group Order'
    );

    // TODO: This will eventually get moved to a common service so that we don't need to depend on on-premise.
    await this.onPremise.endOnPremise();
    this.lastOrderUpdateTimestamp = undefined;
  }

  async joinGroupOrder(
    vendorSlug: string,
    fullName: string | undefined,
    joinAsHost: boolean
  ): Promise<void> {
    if (!this.groupOrder) {
      this.error.reportError('Could not load the group order.');
      return;
    }

    const response = joinAsHost
      ? await this.groupOrder.joinGroupOrderAsHost()
      : await this.groupOrder.joinGroupOrder({
          participantName: fullName || '',
        });
    await this.basket.loadBasket(response.basket.guid);
    // this must be in sync with app/services/bootstrap initBootstrap.
    await this.loadGroupOrder();
    await this.vendor.ensureVendorLoaded(vendorSlug);
    await this.onPremise.onBootstrapInit();
  }

  async lockGroupOrder(lock: boolean) {
    if (!this.isHostMode || !this.groupOrder) return;

    if (lock) {
      await this.groupOrder.lockGroupOrder();
    } else {
      await this.groupOrder.unlockGroupOrder();
    }
  }

  showGroupOrderLockedModal() {
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.groupOrder.inProgressTitle'),
      content: this.intl.t('mwc.groupOrder.inProgressDescription'),
      buttonLabel: this.intl.t('mwc.groupOrder.inProgressButton'),
      clickOutsideToClose: false,
      hideCancelButton: true,
      hideClose: true,
      onConfirm: () => this.router.transitionTo('group-order.participant-confirmation'),
      testSelector: 'locked-group-order-confirmation-modal',
      buttonTestSelector: 'locked-group-order-confirmation-button',
      confirmButtonVariant: Variant.Main,
    });
  }

  // Private methods
  private async checkForGroupOrderBasketUpdate(): Promise<boolean> {
    const result: { lastUpdated: string } = await this.basket.basket?.checkGroupOrderUpdateStatus();
    if (result.lastUpdated !== this.lastOrderUpdateTimestamp) {
      this.lastOrderUpdateTimestamp = result.lastUpdated;
      return true;
    }

    return false;
  }

  private async orderMore(): Promise<void> {
    this.analytics.trackEvent(
      // @ts-ignore -- temp debug event, remove after OLO-105629
      'Clean Up Group Order -- Order More (group-order)'
    );
    await this.cleanupGroupOrder();

    const slug = this.vendor.vendor?.get('slug');

    if (slug) {
      this.router.transitionTo('menu.vendor', slug);
    } else {
      this.router.transitionTo('index');
    }
    if (this.onPremise.experienceType === OnPremiseExperience.ClosedCheck) {
      this.analytics.trackEvent(AnalyticsEvents.OrderMore);
    }
  }

  // Tasks
  refreshBasketTask = taskFor(this.refreshBasketInstance);
  @dropTask *refreshBasketInstance(): Generator<any, any, any> {
    while (!this.isDestroyed) {
      if (
        this.groupOrderId &&
        this.isParticipantMode &&
        !this.canEditGroupOrder &&
        !this.isLocked &&
        !this.router.currentURL.endsWith('/group-order/participant-confirmation')
      ) {
        if (this.isCancelled) {
          this.bus.trigger('confirm', {
            title: this.intl.t('mwc.groupOrder.closedGroupTitle'),
            content: this.intl.t('mwc.groupOrder.closedGroupDescription'),
            buttonLabel: this.intl.t('mwc.groupOrder.closedGroupStartNew'),
            clickOutsideToClose: false,
            hideCancelButton: true,
            hideClose: true,
            onConfirm: async () => await this.orderMore(),
            testSelector: 'closed-group-order-confirmation-modal',
            buttonTestSelector: 'closed-group-order-confirmation-button',
            confirmButtonVariant: Variant.Main,
          });
        } else {
          yield this.router.transitionTo('group-order.participant-confirmation');
        }
      }
      try {
        yield this.timeout(GROUP_ORDER_BASKET_REFRESH_INTERVAL);
        if (this.basket.basket && this.groupOrderId) {
          const shouldUpdate = yield this.checkForGroupOrderBasketUpdate();
          if (shouldUpdate) {
            yield this.basket.refreshBasket();
            yield this.store.findRecord('group-order', this.groupOrderId, { reload: true });
          }
        }
      } catch (e) {
        this.error.sendExternalError(e);
      }
    }
  }
}

// Actions and helpers

declare module '@ember/service' {
  interface Registry {
    'group-order': GroupOrderService;
  }
}
