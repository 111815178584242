import DS from 'ember-data';

import { Code } from 'mobile-web/lib/country';
import { Customer } from 'mobile-web/lib/customer';
import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import { HandoffMode } from 'mobile-web/lib/order-criteria';
import { ContentModelShape } from 'mobile-web/models/content';
import CustomLinkModel from 'mobile-web/models/custom-link';
import { Engine } from 'mobile-web/services/analytics';

import CateringAccountModel from './catering-account';

export type MixpanelBucket = 'default' | 'all';
export default class BootstrapDataModel extends DS.Model {
  @DS.attr('string')
  channelSlug?: string;
  @DS.attr('string')
  basketGuid?: string | null;
  @DS.attr('string')
  basketVendorSlug?: string | null;
  @DS.attr()
  // eslint-disable-next-line no-use-before-define
  channel!: ChannelData;
  @DS.attr('string')
  clientTrackerGuid?: string | null;
  @DS.attr()
  content?: ContentModelShape[];
  @DS.attr()
  cmsContent?: Dict<string>;
  @DS.attr()
  crossChannelAnalytics?: Engine;
  @DS.attr('string')
  currentCountry!: string;
  @DS.attr('string')
  appVersion!: string | null;
  @DS.attr('string')
  error?: string | null;
  @DS.attr('boolean')
  allowSearchEngineIndexing?: boolean;
  @DS.attr('boolean')
  isRestrictedFlow?: boolean;
  @DS.attr('boolean')
  isEmbeddedMode?: boolean;
  @DS.attr('boolean')
  isEmbeddedLevelUp?: boolean;
  @DS.attr('boolean')
  isFacebook?: boolean;
  @DS.attr('boolean')
  isSsoLogin?: boolean;
  @DS.attr('boolean')
  isOloAuthLogin?: boolean;
  @DS.attr('boolean')
  isLinkedOloAuthAndLoyalty!: boolean | null;
  @DS.attr('boolean')
  enableOloAuthLinking?: boolean;
  @DS.attr('string')
  editAccountUrl?: string | null;
  @DS.attr()
  featureFlags!: Array<{
    name: string;
    enabled: boolean;
  }>;
  @DS.attr()
  payload?: AnyObject;
  @DS.attr('string')
  registerAccountUrl?: string | null;
  @DS.attr('string')
  loginProviderName?: string | null;
  @DS.attr()
  guestUser?: Customer | null;
  @DS.attr()
  userId?: EmberDataId | null;
  @DS.attr()
  handoffModes!: Array<{
    id: number;
    label: string;
    type: string;
    timeWantedModes: number[];
    isPrivate?: boolean;
    channel?: EmberDataId;
  }>;
  @DS.attr()
  timeWantedModes!: Array<{
    id: number;
    label: string;
    value: number;
    handoffModes?: EmberDataId[];
    isPrivate?: boolean;
  }>;
  @DS.attr()
  customLinks?: CustomLinkModel[];
  @DS.attr()
  mixpanelAnalyticsIds?: Partial<Record<MixpanelBucket, string>>;
  @DS.attr('string')
  mixpanelUniqueId?: string;
  @DS.attr()
  translationTest?: string;
  @DS.attr()
  encodedTranslation?: string;
  @DS.attr()
  hasOloAuthProvider?: boolean;
  @DS.attr()
  oloAuthApiUrl?: string;
  @DS.attr()
  redirectToCustomSite!: boolean;
  @DS.attr()
  customSiteUrl?: string;
  @DS.attr()
  crossChannelAnalyticsGtm?: Engine;
  @DS.attr()
  showPrivacyLink!: boolean;
  @DS.attr()
  showUserAgreementLink!: boolean;
  @DS.attr()
  checkForHouseAccountOnPasswordReset!: boolean;
  @DS.attr()
  onPremiseSessionData!: {
    tablePosRef?: string;
    experienceType?: OnPremiseExperience;
  };
  @DS.attr()
  serveNextConfig!: {
    isDisabled: boolean;
    pathConfigs: Array<{
      cookieName: string;
      flagValue: number;
      fullyMigrated?: boolean;
    }>;
  };
  @DS.attr()
  cateringAccounts?: CateringAccountModel[];

  get decodedTranslation(): string | undefined {
    return this.encodedTranslation ? atob(this.encodedTranslation) : undefined;
  }
}

export type CountrySettings = Partial<{
  [key in Code]: { name: string; optIn: boolean; showOptInDetails: boolean };
}>;

export type LoginProviderCategory =
  | 'None'
  | 'Other'
  | 'Legacy'
  | 'Loyalty'
  | 'Social'
  | 'Borderless';

export type LoginProvider = {
  id: number;
  slug: string;
  name: string;
  userEditAccountUrl?: string;
  displayText?: string | null | undefined;
  isOloAuth?: boolean;
  enableOloAuthLinking?: boolean;
  category?: LoginProviderCategory | null;
};

// FIX TYPES: not `Partial` once we know which are actually always required.
export type ChannelSettings = Partial<{
  advanceOrderDays: number;
  showSpecialInstructions: boolean;
  specialInstructionsMaxLength: number;
  showProductRecipientNameLabel: boolean;
  fullSiteUrl: string;
  showOffersInbox: boolean;
  supportsOloLogin: boolean;
  useThirdPartyLogin: boolean;
  basketTransfer: boolean;
  groupOrdering: boolean;
  guestOrdering: boolean;
  curbside: boolean;
  manualFire: boolean;
  parentSiteUrl: string | null;
  showUnavailableProducts: boolean;
  upsell: boolean;
  channelCountrySettingsByCountryCode: CountrySettings;
  requirePhoneNumber: boolean;
  dineInRequirePhoneNumber: number;
  dineInRequireEmail: boolean;
  redirectDineInToClosedCheck: boolean;
  facebookOrderAppId: string | null;
  facebookAppId: string | null;
  enableFacebookWebViews: boolean;
  showSocialIntegration: boolean;
  defaultToGuestOrdering: boolean;
  favorites: boolean;
  separateBookingAndReceivingCustomer: boolean;
  allowUserFeedback: boolean;
  genericFeedbackUrl: string | null;
  orderSpecificFeedbackUrl: string | null;
  otherDeliveryInstructionsMaxLength: number;
  requireDeliveryInstructions: boolean;
  cacheVersion: number | string;
  responsivePreviewEnabled: boolean;
  doCreditCardStreetAddressValidation: boolean;
  hideMarketingOptIn: boolean;
  showGuestMarketingOptIn: boolean;
  showVendorPhoneNumber: boolean;
  defaultChoicesToMax: boolean;
  showParticipatingRestaurants: boolean;
  channelAllyableLicense: string;
  showFullMenu: boolean;
  showProductAvailability: boolean;
  showSmsOptIn: boolean;
  allowGtmOnSecureRoutes: boolean;
  showPhoneNumberIfNotRequired: boolean;
  surveyUrl: string | null;
  hideHoursForHiddenHandoffModes: boolean;
}> & {
  // Types in this section are known to be accurate

  // `basketMaxQuantity` is optional for backwards compatibility until the
  // platform mapping has been deployed at which point it will no longer be
  // optional.
  basketMaxQuantity?: number;
  basketProductMaxQuantity: number;
  showMenuCategoryNames: boolean;
  showProductMenuPrices: boolean;
  showProductPriceTicker: boolean;
  disableDispatchDriverMap: boolean;
  handoffModesHiddenFromSelection: HandoffMode[];
};

export type AnalyticsConfig = {
  type: 'gtm' | 'ua';
  identifier: string;
  isCrossChannel?: boolean;
};

export type ChannelData = {
  id: number;
  guid: string;
  analytics?: Engine[];
  name: string;
  internalName: string;
  loginProviders: LoginProvider[];
  settings: ChannelSettings;
  cdnUrl: string;
  handoffModes: number[];
  iOSAppIdentifier?: string | null;
  androidAppIdentifier?: string | null;
  vendors?: Array<EmberDataId>;
  billingSchemes?: Array<EmberDataId>;
  defaultHandoffMode: HandoffMode;
};
