import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import { trackViewUpsellEvent } from 'mobile-web/lib/upsell';
import UpsellGroupModel from 'mobile-web/models/upsell-group';
import AnalyticsService from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';

import style from './index.m.scss';

export default class CartUpsells extends Component {
  // Service injections
  @service basket!: BasketService;
  @service store!: DS.Store;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get availableUpsellGroups(): UpsellGroupModel[] {
    const upsells = this.staticUpsellGroups;
    if (this.basket.isOpen && upsells.length !== 0 && !this.basket.enableSmartUpsellGroups) {
      trackViewUpsellEvent(upsells, this.store, this.analytics);
    }
    return upsells;
  }

  get staticUpsellGroups(): UpsellGroupModel[] {
    return this.basket.basket?.upsellGroups?.filter(ug => ug.items?.any(x => !!x)) ?? [];
  }

  get hasUpsellData(): boolean {
    return (
      (this.staticUpsellGroups || []).length > 0 ||
      (this.basket.enableSmartUpsellGroups &&
        (this.basket.getSmartUpsellDataIfVisible.isRunning ||
          (this.basket.smartUpsellGroups || []).length > 0))
    );
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::Upsells': typeof CartUpsells;
  }
}
