//  _____  ______ _____  _____  ______ _____       _______ ______ _____
// |  __ \|  ____|  __ \|  __ \|  ____/ ____|   /\|__   __|  ____|  __ \
// | |  | | |__  | |__) | |__) | |__ | |       /  \  | |  | |__  | |  | |
// | |  | |  __| |  ___/|  _  /|  __|| |      / /\ \ | |  |  __| | |  | |
// | |__| | |____| |    | | \ \| |___| |____ / ____ \| |  | |____| |__| |
// |_____/|______|_|    |_|  \_\______\_____/_/    \_\_|  |______|_____/

// The Open Source Licenses page has been fully migrated to Serve-Next!

// Traffic is no longer being sent to the Serve-Ember version of the page.

// If you need to make changes to the Open Source Licenses page, please do so in Serve-Next instead.

// Open Source Licenses in Serve-Next: https://github.com/ololabs/serve-next/tree/main/src/pages/open-source-licenses

import Component from '@glimmer/component';

import { License } from 'mobile-web/lib/license';

import style from './index.m.scss';

interface Args {
  // Required arguments
  licenses: License[];

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class OpenSourceLicensesRoute extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::OpenSourceLicensesRoute': typeof OpenSourceLicensesRoute;
  }
}
